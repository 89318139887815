














































import { Notify } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import ScrollX from "@/components/ShareComponent/ScrollViewX.vue"
import List from "@/components/ShareComponent/PullDownUp.vue"
import TakeItem from "@/components/HelpComponent/TakeItemComponent.vue"
import FooterBottom from "@/components/ShareComponent/ListBottom.vue"
import { AllHelpUpData } from "@/Api/Help/interfaceType"
import { MyHelpItem } from "@/Type/index"
import Stroage from "@/util/Storage"
import { HelpStore } from "@/store/modules/Help/Help"
import { TakeHelp as TakeUpHelp } from "@/Api/Help/index"
import { Vue,Component, Watch } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface TakeHelpType<T=string>{
    loadingShow:boolean;
    userId:T|number;
    token:T;
    NavList:Array<{ title:T,state:number }>
    NavIndex:number
    UpData:AllHelpUpData
    PullDown:boolean;
    UpDown:boolean;
    StopUp:boolean
    List:Array<MyHelpItem>
    ScrollNum:number;

    initData():void;
    handleChangeIndex( data:any,index:number ):void;
    handlePullDown(data?:any):void;
    handleUpDown(data?:any):void;
    handleUpData(bool?:boolean):void;
    handleError(data:string):void;
}

@Component({ name:"TakeHelp",components:{ ScrollX,List,FooterBottom,TakeItem,HeadTop } })
export default class TakeHelp extends ZoomPage implements TakeHelpType{
    loadingShow = true
    userId:string|number = ""
    token = ""
    NavList = [
        { title:"全部",state:-1 },
        { title:"未支付",state:0 },
        { title:"待接单",state:1 },
        { title:"已接单",state:2 },
        { title:"待确认",state:3 },
        { title:"已完成",state:4 },
        { title:"异常中",state:5 },
        { title:"已取消",state:6 },
    ]
    NavIndex = 0
    UpData:AllHelpUpData = {
        pageNo:1,
        pageSize:15
    }
    PullDown = false
    UpDown = true
    StopUp = false
    List:Array<MyHelpItem> = []
    ScrollNum = 0

    mounted() {
        this.loadingShow = false
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
        this.initData()
    }

    initData(){
        this.UpData = HelpStore.GetHelpTakeUpData
        if( "status" in this.UpData ){
            if( Number(this.UpData.status) >= 0 ){
                this.NavIndex = Number( this.UpData.status ) + 1
            }
        }else{
            delete this.UpData.status
        }
        if( HelpStore.GetHelpTakeList.length ){
            this.List = HelpStore.GetHelpTakeList
            this.UpDown = false
            this.loadingShow = false
            let time = setTimeout(()=>{
               this.ScrollNum = HelpStore.GetHelpScroll.TakeHelpScroll
               clearTimeout( time )
           },100)
        }else{
            this.handleUpData(true)
        }
    }

    handleChangeIndex( data:any,index:number ){
        this.UpDown = true
        this.StopUp = false
        this.UpData.pageNo = 1
        if( index ){
            if( index != this.NavIndex ){
               this.NavIndex = index
               this.UpData.status = data.state
               this.handleUpData( true )
            }
        }else{
            if( index != this.NavIndex ){
                this.NavIndex = index
                delete this.UpData.status
                this.handleUpData( true )
            }
        }
    }

    handlePullDown(data?:any){
        this.PullDown = false
        this.StopUp = false
        this.UpData.pageNo = 1
        TakeUpHelp({
            userId:this.userId,
            token:this.token
        },this.UpData).then(res=>{
            this.PullDown = true
            if( res.message.code === "200" ){
                if( res.data.length < this.UpData.pageSize ){
                    this.StopUp = true
                }
                this.List = res.data
            }else{
                this.handleError( res.message.msg )
            }
        })
    }

    handleUpDown(data?:any){
        if( this.StopUp )return;
        this.UpData.pageNo += 1
        this.handleUpData()
    }

    handleUpData(bool?:boolean){
        this.UpDown = true
        TakeUpHelp({
            userId:this.userId,
            token:this.token
        },this.UpData).then(res=>{
            this.loadingShow = false
            this.UpDown = false
            if( res.message.code === "200" ){
                if( res.data.length < this.UpData.pageSize ){
                    this.StopUp = true
                }
                if( bool ){
                    this.List = res.data
                }else{
                    this.List = this.List.concat( res.data )
                }
            }else{
                this.handleError( res.message.msg )
            }
        })
    }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
    }

   @Watch("UpData",{deep:true})
   ChangeUpData(newVal:AllHelpUpData){
       HelpStore.SetHelpTakeUpData(newVal)
   }

   @Watch("List")
   ChangeList(newVal:Array<MyHelpItem>){
       HelpStore.SetHelpTakeList( newVal )
   }
}
