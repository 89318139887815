




















































import { Notify,Toast } from "vant"
import { FillNote } from "@/Note/index"
import Stroage from "@/util/Storage"
import { UserType } from "@/Api/Basics/InterfaceType"
import { HelpStore } from "@/store/modules/Help/Help"
import { IndexStore } from "@/store/modules/Index"
import { MyHelpItem } from "@/Type/index"
import { HelpAffirm } from "@/Api/Help/index"
import { SendNot } from "@/Api/Basics/index"
import { Vue,Component, Prop, Emit } from "vue-property-decorator"

interface TakeItemComponentType<T=string>{
    loadingShow:boolean;
    userId:T|number;
    token:T;
    UserInfo:UserType

    handleToPathSuc(data:MyHelpItem):void;
    handleClickAffirm():void;
    handleFilterTime(str:string):string
    handleError(data:string):void;
}

@Component({ name:"TakeItemComponent" })
export default class TakeItemComponent extends Vue implements TakeItemComponentType{
    loadingShow = false
    userId:string|number = ""
    token = ""
    UserInfo:UserType = {}

    mounted() {
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token") 
        try{
            this.UserInfo = Stroage.GetData_ && Stroage.GetData_("UserData") 
        }catch(cat){ new Error( cat ) }
    }

    handleToPathSuc(data:MyHelpItem){
        let ScrollData = HelpStore.GetHelpScroll
        let Num = IndexStore.GetScrollNum
        ScrollData.TakeHelpScroll = Num
        HelpStore.SetHelpScroll( ScrollData )
        this.$router.push({
            path:"/helpSuc",
            query:{
                id:this.GetData.id as string,
                take:"true"
            }
        })
    }

    handleClickAffirm(){
       this.loadingShow = true
       new Promise((reslove,reject)=>{
           HelpAffirm({
               userId:this.userId,
               token:this.token
           },{
               id:this.GetData.id as string
           }).then(res=>{
               if( res.message.code === "200" ){
                   reslove(true)
               }else{
                   reject(res)
               }
           })
       }).then(res=>{
           if ( this.GetData.destAddressPhone || this.GetData.userPhone ){
               let Str = FillNote;
               let regPhone = /手机号/
               let regName = /名字/
               Str = Str.replace( regPhone, this.UserInfo.phone + "" )
               Str = Str.replace( regName, this.UserInfo.name || this.UserInfo.nickName + "" )
               SendNot({
                   userId:this.userId,
                   token:this.token
               },{
                   phone:this.GetData.destAddressPhone as string || this.GetData.userPhone as string,
                   msg:Str,
               }).then(res=>{
                   this.loadingShow = false
                   if( res.message.code === "200" ){
                       Toast({
                       message:"确认送达",
                       icon:"none",
                       className:"ToastClassName"
                       })
                       this.PullReftch()
                   }else{
                       this.handleError( res.message.msg )
                   }
               })
           }else{
               this.loadingShow = false
               Toast({
               message:"确认送达",
               icon:"none",
               className:"ToastClassName"
               })
               this.PullReftch()
           }
       }).catch(cat=>{
           this.loadingShow = false
           this.handleError( cat.message.msg )
       })
    }

    handleFilterTime(str:string){
        return str.split(" ")[0]
    }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });            
   }

    get GetFitlerBtn(){
        switch ( this.GetData.status + "" ) {
           case "0":
               return "未支付"
           case "1":
               return "待接单"
           case "2":
               return "已接单"
           case "3":
               return "待确认"
           case "4":
               return "已完成"
           case "5":
               return "异常订单"
           case "6":
               return "订单已取消"
           default:
               return "啥也不是"
       }
    }

    @Prop(Object)
    data!:MyHelpItem

    get GetData(){
        return this.data
    }

    @Emit("PullReftch")
    PullReftch(){
        return true
    }
}
